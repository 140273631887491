
import moment from 'moment';
import suggestions from '../../mixins/suggestions';
import HintText from '../hint-text';
import Suggestion from '../suggestion';
import FileUploadInput from '../file-upload-input.vue';
import ContactCompanySelect from './contact-company-select';
import ContactDepartmentSelect from './contact-department-select';
import ContactTypeSelect from './contact-type-select';
import ContactName from './contact-name';
import ContactInvestorTierSelect from './contact-investor-tier-select.vue';

export default {
    name: 'ContactEdit',
    components: {
        FileUploadInput,
        ContactName,
        Suggestion,
        ContactCompanySelect,
        ContactDepartmentSelect,
        ContactTypeSelect,
        ContactInvestorTierSelect,
        HintText
    },
    mixins: [suggestions],
    props: {
        contact: {
            type: Object,
            required: true
        },
        emailRequired: {
            type: Boolean,
            default: false
        },
        debounceTime: {
            type: Number,
            default: 750
        }
    },
    data() {
        return {
            cityOptions: [],
            collapseShown: false,
            isNew: false,
            countryOptions: [],
            countryUrl: '/api/countries',
            photo: null,
            loading: false,
            originalContact: {},
            highlightDropArea: false,
            cancelSource: null,
            contactWorkingCopy: {},
            wasInactive: false,
            duplicateContacts: [],
            emailRegex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        };
    },
    computed: {
        assistant: {
            get() {
                if (this.contactWorkingCopy.assistantContact) {
                    return this.contactWorkingCopy.assistantContact;
                } else if (this.contactWorkingCopy.assistantEmail) {
                    return {
                        nameWithCompany: this.contactWorkingCopy.assistantEmail
                    };
                }
                return null;
            },
            set(newVal) {
                if (!newVal) {
                    this.$set(this.contactWorkingCopy, 'assistantEmail', null);
                    this.$set(this.contactWorkingCopy, 'assistantContact', null);
                } else if (newVal['@id']) {
                    this.$set(this.contactWorkingCopy, 'assistantEmail', null);
                    this.$set(this.contactWorkingCopy, 'assistantContact', newVal);
                } else if (newVal.nameWithCompany) {
                    this.$set(this.contactWorkingCopy, 'assistantEmail', newVal.nameWithCompany);
                    this.$set(this.contactWorkingCopy, 'assistantContact', null);
                }
            }
        },
        activeFrom: {
            get() {
                if (this.contactWorkingCopy && this.contactWorkingCopy.activeFrom) {
                    return moment(this.contactWorkingCopy.activeFrom).format('YYYY-MM-DD');
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.$set(this.contactWorkingCopy, 'activeFrom', newValue || null);
            }
        },
        activeTo: {
            get() {
                if (this.contactWorkingCopy && this.contactWorkingCopy.activeTo) {
                    return moment(this.contactWorkingCopy.activeTo).format('YYYY-MM-DD');
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.$set(this.contactWorkingCopy, 'activeTo', newValue || null);
            }
        },
        birthday: {
            get() {
                if (this.contactWorkingCopy && this.contactWorkingCopy.birthday) {
                    return moment(this.contactWorkingCopy.birthday).format('YYYY-MM-DD');
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.$set(this.contactWorkingCopy, 'birthday', newValue || null);
            }
        },
        selectedCompany: {
            get() {
                return this.contactWorkingCopy.company;
            },
            set(newValue) {

                this.$set(this.contactWorkingCopy, 'company', newValue);
            }
        },
        currentEmail: {
            get() {
                return this.contactWorkingCopy.email;
            },
            set(newValue) {
                this.$set(this.contactWorkingCopy, 'email', newValue);
            }
        }
    },
    watch: {

        'contact.id': {
            handler() {
                this.originalContact = structuredClone(this.contact);
                this.contactWorkingCopy = structuredClone(this.contact);
                this.wasInactive = this.contact.activeTo !== null;
            },
            immediate: true
        },
        currentEmail(val) {
            if (val && !this.selectedCompany) {
                this.findCompanyByEmailDomain(val);
            }
        },
        contactWorkingCopy: {
            handler(newValue) {
                this.$emit('update:contact', newValue);
            },
            deep: true
        }

    },
    mounted() {
        this.loadCities();
        this.loadCountryOptions();
        this.findDuplicateContacts();
        this.$refs.collapse.addEventListener('show.bs.collapse', () => {
            this.collapseShown = true;
        });
        this.$refs.collapse.addEventListener('hide.bs.collapse', () => {
            this.collapseShown = false;
        });

    },
    created() {
        this.isNew = this.$route.params.id === 'new';

        // this.extendedForm = this.contact.id != null;
    },
    methods: {
        loadCities() {
            this.$axios.get('/api/contacts/fetch_cities')
                .then((response) => {
                    this.cityOptions = response.data;
                });
        },
        onCitySelect() {
            this.$axios.post('/api/contacts/fetch_countries_and_states',
                { city: this.contactWorkingCopy.city })

                .then((response) => {
                    this.contactWorkingCopy.country = response.data[0];
                    this.contactWorkingCopy.state = response.data[1];
                });
        },

        findDuplicateContacts() {
            if (!this.contactWorkingCopy) {
                return;
            }

            const params = {
                limit: 5
            };

            if (this.contactWorkingCopy.id) {
                params.exclude = this.contactWorkingCopy.id;
            }

            const promises = [];

            if (this.contactWorkingCopy.email) {
                promises.push(this.$axios.get('/api/contacts/for_select?' + this.buildQueryString({
                    ...params,
                    term: this.contactWorkingCopy.email
                })));
            }

            if (this.contactWorkingCopy.firstName && this.contactWorkingCopy.lastName) {
                promises.push(this.$axios.get('/api/contacts/for_select?' + this.buildQueryString({
                    ...params,
                    term: `${this.contactWorkingCopy.firstName ?? ''} ${this.contactWorkingCopy.lastName ?? ''}`
                })));
            }

            if (promises.length === 0) {
                return;
            }

            Promise.all(promises).then(responses => {
                const contactByContactIds = new Map();

                for (const response of responses) {
                    for (const contact of response.data['hydra:member']) {
                        contactByContactIds.set(contact.id, contact);
                    }
                }

                this.duplicateContacts = Array.from(contactByContactIds.values());
            });
        },
        findCompanyByEmailDomain(email) {
            return new Promise((resolve) => {

                clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(() => {

                    if (this.cancelSource) {
                        this.cancelSource.cancel('Operation canceled by the user.');
                    }

                    this.cancelSource = this.$axios.CancelToken.source();

                    this.$axios.get('/api/companies/by_email_domain/' + email, {
                        cancelToken: this.cancelSource.token
                    }).then((response) => {
                        if (response.data) {
                            this.selectedCompany = response.data;
                        }
                        resolve();
                    }).catch((thrown) => {
                        if (this.$axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.log(thrown);
                        }
                    });

                }, this.debounceTime);
            });
        },
        loadCountryOptions() {
            this.$axios.get(this.countryUrl)
                .then((response) => {
                    this.countryOptions = response.data;
                });
        },
        dragover(event) {
            event.preventDefault();
            this.highlightDropArea = true;
        }
    }
};
