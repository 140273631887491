import { render, staticRenderFns } from "./investment-preferences-modal.vue?vue&type=template&id=f9b9875c&scoped=true"
import script from "./investment-preferences-modal.vue?vue&type=script&lang=js"
export * from "./investment-preferences-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9b9875c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SaveButton: require('/codebuild/output/src2285020857/src/web/components/save-button.vue').default})
