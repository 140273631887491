
import moment from 'moment';
import debounce from 'lodash/debounce';
import TableActions from '../table-actions';
import modals from '../../mixins/modals';
import TableFilters from '../table-filters';
import Gmap from '../helpers/gmap';
import FavStar from '../fav-star';
import QuintableSearchInput from '../helpers/quintable-search-input.vue';
import RemoveFromEventModal from '../contacts/remove-from-event-modal.vue';
import { CLASSIFICATION_OPTIONS } from '../../mixins/company-classification';
import DownloadSelectColumnsModal from '../download-select-columns-modal.vue';
import RangeWithInput from '../helpers/range-with-input.vue';
import cachedCompanyProvider from '../../mixins/cached-company-provider';
import { TIER_OPTIONS } from '../../mixins/contact-tiers';
import LoadingSpinner from '../loading-spinner.vue';
import ImportContactsModal from './import-contacts-modal';
import ContactBulkOperationModal from './contact-bulk-operation-modal';
import AddContactModal from './add-contact-modal';
import EditContactModal from './edit-contact-modal';
import OfficeContactsModal from './office-contacts-modal';
import ContactDeleteModal from './contact-delete-modal';
import ContactKnownPeopleModal from './contact-known-people-modal';
import ContactNameCell from './contacts-table-cells/contact-name-cell.vue';
import ContactPhoneCell from './contacts-table-cells/contact-phone-cell.vue';
import ContactCompanyCell from './contacts-table-cells/contact-company-cell.vue';
import ContactEmailCell from './contacts-table-cells/contact-email-cell.vue';
import ContactEventParticipationCell from './contacts-table-cells/contact-event-participation-cell.vue';
import ContinueContactModal from './continue-contact-modal';
import ContactTypeSelect from './contact-type-select.vue';
import PushContactsToSessionsModal from './push-contacts-to-sessions-modal.vue';
import optionButtonTooltip from '~/mixins/option-button-tooltip';
import emailPopoverActionsMixin from '~/mixins/email-popover-actions';
import events from '~/mixins/events';

export default {
    name: 'ContactsTable',
    components: {
        LoadingSpinner,
        ContactTypeSelect,
        RangeWithInput,
        DownloadSelectColumnsModal,
        QuintableSearchInput,
        RemoveFromEventModal,
        ContactEventParticipationCell,
        ContactEmailCell,
        ContactCompanyCell,
        ContactPhoneCell,
        ContactNameCell,
        FavStar,
        TableFilters,
        OfficeContactsModal,
        EditContactModal,
        ContactBulkOperationModal,
        ImportContactsModal,
        TableActions,
        AddContactModal,
        Gmap,
        ContactDeleteModal,
        ContactKnownPeopleModal,
        ContinueContactModal,
        PushContactsToSessionsModal
    },
    mixins: [optionButtonTooltip, modals, emailPopoverActionsMixin, cachedCompanyProvider, events],
    props: {
        openedFilter: {
            type: Boolean,
            default: false
        },
        company: {
            type: Object,
            default: null
        },
        event: {
            type: Object,
            default: null
        },
        userId: {
            type: Number,
            default: null
        },
        showFilters: {
            type: Boolean,
            default: true
        },
        favoritesOnly: {
            type: Boolean,
            default: false
        },
        tableUpdate: {
            type: Date,
            default: null
        },
        nameLinks: {
            type: Boolean,
            default: true
        },
        showImport: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        showEdit: {
            type: Boolean,
            default: false
        },
        showDelete: {
            type: Boolean,
            required: false,
            default: true
        },
        showRemoveFromEvent: {
            type: Boolean,
            required: false,
            default: false
        },
        allowEditEventParticipation: {
            type: Boolean,
            default: true
        },
        enableSelect: {
            type: Boolean,
            default: false
        },
        narrow: {
            type: Boolean,
            default: false
        },
        showThumbnails: {
            type: Boolean,
            default: false
        },
        showAssistantMarker: {
            type: Boolean,
            default: false
        },
        additionalFilters: {
            type: Object,
            default: null
        },
        contactListId: {
            type: Number,
            default: null
        },
        showFavStar: {
            type: Boolean,
            default: true
        },
        showRedFlag: {
            type: Boolean,
            default: true
        },
        search: {
            type: String,
            default: null
        },
        typeDropdown: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            searchQuery: '',
            selectedCompany: null,
            selectedEvent: null,
            selectedContactTypes: [],
            selectedInvestorTiers: [],
            selectedContactDepartments: [],
            contactTypeOptions: [],
            contactDepartmentOptions: [],
            selectedCities: [],
            selectedStates: [],
            selectedCountries: [],
            selectedGenders: [],
            selectedEventRole: null,
            selectedIsAttendingEvent: this.event ? ['Yes', 'Unknown'] : [],
            selectedHasAttendedEvent: null,
            filterOptions: {
                cities: [],
                states: [],
                countries: [],
                eventRoles: [],
                companyRoles: [],
                sessionsTypes: []
            },
            inactivePermanently: null,
            continuingContact: null,
            tableUpdated: new Date(),
            importModal: null,
            contactToDeleteId: null,
            contactToRemoveFromEventId: null,
            newContact: null,
            addContactReset: 0,
            contactsUpdated: moment().unix(),
            contactToEdit: null,
            onlyOwnCompanyContacts: false,
            notImportedOnlyContacts: false,
            hideInactiveContacts: true,
            hideTemporaryToDeleteContacts: true,
            onlyTemporaryContacts: false,
            hideTemporaryContacts: !this.event,
            hideExcludedFromEmails: false,
            excludedFromEmailsOnly: false,
            hideNoCompanyContacts: false,
            selectedRows: [],
            preSelectedRows: [],
            preSelectedRowIds: [],
            loading: false,
            ajaxRows: null,
            ajaxAll: 0,
            pageChanged: false,
            mode: 'filtered',
            bulkModal: null,
            bulkUpdated: moment().unix(),
            selectedUser: null,
            selectedRow: null,
            selectedContactGroup: null,
            selectedCompanyGroup: null,
            selectedTargetingGroup: null,
            initialLoad: true,
            favoritesOnlyFilter: false,
            contactsFromFavoriteCompanies: false,
            mostUsedContactGroups: [],
            mostUsedCompanyGroups: [],
            preparedUsersForSelect: [],
            selectedCrossConnectionUser: null,
            contactIds: null,
            tableLoaded: false,
            bulkTriggered: false,
            mapKey: 'initial-map-key',
            area: null,
            baseKey: null,
            companyCharacteristics: [],
            companyRoles: [],
            isPrivateCompany: null,
            knownPeople: [],
            fromContactId: [],
            downloadQuery: null,
            onlyInactiveContacts: false,
            moreThanEmailOnlyContacts: true,
            contactsWithoutPosInvestmentPreferences: false,
            contactsWithoutNegInvestmentPreferences: false,
            alreadyContinuesAnotherContact: null,
            alreadyContinuedByAnotherContact: null,
            CLASSIFICATION_OPTIONS,
            positiveInvestmentPreferenceCharacteristics: [],
            negativeInvestmentPreferenceCharacteristics: [],
            searchInput: '',
            continueModalUpdated: moment().unix(),
            participationIdByContactIds: {},
            participationByContactIds: {},
            selectedMarketListMembers: [],
            onlyMarketListContacts: false,
            onlyBlackList: false,
            isSyncingWithSessions: false,
            minSessionsMeetingRequests: null,
            maxSessionsMeetingRequests: null,
            selectedSessionsTypes: [],
            sessions1x1Access: null,
            TIER_OPTIONS,
            pushToSessions: 0
        };
    },
    computed: {
        sortOrder() {
            if (this.event) {
                return [
                    {
                        index: 12,
                        asc: false
                    }
                ];
            }
            if (this.company) {
                return [
                    {
                        index: 0,
                        asc: true
                    }
                ];
            }
            return [
                {
                    index: 1,
                    asc: true
                },
                {
                    index: 0,
                    asc: true
                }
            ];
        },
        bulkFilters() {
            switch (this.mode) {
                case 'selected':
                    return {
                        ids: structuredClone(this.preSelectedRowIds)
                    };
                case 'page':
                    return {
                        ids: this.ajaxRows.map(function(row) {
                            return row.contact_id;
                        })
                    };
                case 'filtered':
                default:
                    if (this.searchQuery) {
                        return { ...this.filters, search_term: this.searchQuery };
                    }
                    return this.filters;
            }
        },
        milliseconds() {
            return this.tableUpdated.getUTCMilliseconds();
        },
        config() {
            return {
                columns: [
                    {
                        headline: 'Name',
                        sort: true
                    },
                    {
                        headline: 'Relationship Status',
                        hidden: !this.company
                    },
                    {
                        headline: 'Company',
                        breakpoint: 'sm',
                        sort: true,
                        hidden: !!this.company
                    },
                    {
                        headline: 'Title',
                        breakpoint: this.narrow ? 'all' : this.event ? 'lg' : 'md'
                    },
                    {
                        headline: 'Type',
                        breakpoint: this.narrow ? 'all' : this.event ? (this.typeDropdown ? '' : 'lg') : 'md'
                    },
                    {
                        headline: 'Email',
                        breakpoint: this.narrow ? 'all' : this.event ? 'lg' : 'md'
                    },
                    {
                        headline: 'Phone',
                        breakpoint: this.narrow ? 'all' : this.event ? 'xxl' : 'xxl'
                    },

                    {
                        headline: 'Is Attending',
                        breakpoint: this.narrow ? 'all' : 'md',
                        hidden: !this.event
                    },
                    {
                        headline: 'Has Attended',
                        breakpoint: this.narrow ? 'all' : 'md',
                        hidden: !this.event || this.event.isFuture
                    },
                    {
                        headline: 'Role',
                        breakpoint: this.narrow ? 'all' : 'md',
                        hidden: !this.event
                    },
                    {
                        headline: 'Meetings',
                        breakpoint: this.narrow ? 'all' : 'md',
                        hidden: !this.event
                    },
                    {
                        headline: 'Sessions Type',
                        breakpoint: this.narrow ? 'all' : 'md',
                        hidden: true // !this.event || !this.event.sessionsId,
                    },
                    {
                        headline: 'Sessions Meeting Requests',
                        breakpoint: this.narrow ? 'all' : 'md',
                        hidden: !this.event || !this.event.sessionsId
                    },
                    {
                        headline: 'Invited By',
                        breakpoint: this.narrow ? 'all' : 'md',
                        hidden: !this.event || !this.event.sessionsId
                    },
                    {
                        headline: 'Added at',
                        breakpoint: this.narrow ? 'all' : 'md',
                        hidden: !this.event,
                        sort: true
                    },
                    {
                        headline: 'Actions',
                        hideHeadlineBreakpoint: 'all',
                        align: 'end'
                    }
                ],
                pagination: 50,
                search: this.showFilters,
                select: this.enableSelect,
                selectPosition: 'pre',
                selectAll: this.enableSelect,
                prettySelect: this.enableSelect,
                pageSortSelect: this.enableSelect,
                emptyPlaceholder: 'No Contacts',
                ajaxUrl: '/api/contacts/list',
                disallowAllOption: true,
                multiSort: true,
                searchPlaceholder: 'Search (Full-text or IDs)',
                requestMethod: 'POST',
                searchContainerClass: 'container-class'
            };
        },
        userIdFinal() {
            if (this.selectedUser) {
                return this.selectedUser.id;
            } else {
                return this.onlyOwnCompanyContacts && this.userInfo ? this.userInfo.id : this.userId;
            }
        },
        filters() {
            const currentFilters = {
                company: this.selectedCompany ? this.selectedCompany.id : null,
                contactDepartments:
                    this.selectedContactDepartments.length > 0
                        ? this.selectedContactDepartments.map((cd) => cd.id)
                        : null,
                contactTypes:
                    this.selectedContactTypes.length > 0 ? this.selectedContactTypes.map((ct) => ct.id) : null,
                investorTiers:
                    this.selectedInvestorTiers.length > 0 ? this.selectedInvestorTiers.map((t) => t.value) : null,
                favoritesOnly: this.favoritesOnly || this.favoritesOnlyFilter,
                contactsFromFavoriteCompanies: this.contactsFromFavoriteCompanies,
                userId: this.userIdFinal,
                moreThanEmailOnly: this.moreThanEmailOnlyContacts,
                contactsWithoutPosInvestmentPreferences: this.contactsWithoutPosInvestmentPreferences,
                contactsWithoutNegInvestmentPreferences: this.contactsWithoutNegInvestmentPreferences,
                notImportedOnly: this.notImportedOnlyContacts,
                hideInactive: this.hideInactiveContacts,
                onlyInactive: this.onlyInactiveContacts,
                hideTemporaryToDelete: this.hideTemporaryToDeleteContacts,
                onlyTemporary: this.onlyTemporaryContacts,
                hideTemporary: this.hideTemporaryContacts,
                hideExcludedFromEmails: this.hideExcludedFromEmails,
                excludedFromEmailsOnly: this.excludedFromEmailsOnly,
                hideNoCompany: this.hideNoCompanyContacts,
                contactGroupIds: this.selectedContactGroup ? [this.selectedContactGroup.id] : null,
                companyGroupIds: this.selectedCompanyGroup ? [this.selectedCompanyGroup.id] : null,
                targetingGroupIds: this.selectedTargetingGroup ? [this.selectedTargetingGroup.id] : null,
                crossConnectionUserId: this.selectedCrossConnectionUser ? this.selectedCrossConnectionUser.id : null,
                contactIds: this.contactIds,
                cities: this.selectedCities,
                states: this.selectedStates,
                countries: this.selectedCountries,
                genders: this.selectedGenders,
                eventRole: this.selectedEventRole,
                isAttendingEvent: this.selectedIsAttendingEvent,
                hasAttendedEvent: this.selectedHasAttendedEvent,
                area: this.area,
                eventId: this.selectedEvent ? this.selectedEvent.id : null,
                companyRoles: this.companyRoles,
                isPrivateCompany: this.isPrivateCompany,
                companyCharacteristics: this.companyCharacteristics,
                positiveInvestmentPreferenceCharacteristics:
                    this.positiveInvestmentPreferenceCharacteristics.length > 0
                        ? this.positiveInvestmentPreferenceCharacteristics.map((c) => c.id)
                        : null,
                negativeInvestmentPreferenceCharacteristics:
                    this.negativeInvestmentPreferenceCharacteristics.length > 0
                        ? this.negativeInvestmentPreferenceCharacteristics.map((c) => c.id)
                        : null,
                marketListMembers: this.selectedMarketListMembers,
                sessionsTypes: this.selectedSessionsTypes,
                onlyMarketListContacts: this.onlyMarketListContacts,
                onlyBlackList: this.onlyBlackList,
                sessions1x1Access: this.sessions1x1Access,
                inactivePermanently: this.inactivePermanently,
                continuingContact: this.continuingContact
            };

            if (this.event && this.event.sessionsId) {
                currentFilters.minSessionsMeetingRequests = this.minSessionsMeetingRequests;
                currentFilters.maxSessionsMeetingRequests = this.maxSessionsMeetingRequests;
            }

            if (this.additionalFilters) {
                // Merge current and additional filters.
                return { ...currentFilters, ...this.additionalFilters };
            }
            return currentFilters;
        },
        query() {
            return {
                search: this.filters.contactIds ? '' : this.searchQuery,
                filters: JSON.stringify(this.filters)
            };
        },
        contactsUpdatedKey() {
            return this.baseKey + this.contactsUpdated;
        }
    },
    watch: {
        selectedEvent() {
            if (!this.selectedEvent) {
                this.selectedEventRole = null;
                this.selectedIsAttendingEvent = [];
                this.selectedHasAttendedEvent = null;
            }
        },
        filters: {
            handler() {
                this.pageChanged = true;
            },
            deep: true
        },
        tableUpdate() {
            this.tableUpdated = this.tableUpdate;
            // if(this.filterOptions.countries.length === 0 && this.showFilters) {
            //     this.loadContactFilterOptions();
            //     this.loadContactTypeOptions();
            //     this.loadContactDepartmentOptions();
            //     this.loadMostUsedContactGroups();
            // }
        },
        preSelectedRowIds: {
            handler(val) {
                this.$emit('input', JSON.parse(JSON.stringify(val)));
            },
            deep: true
        },
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].contact_id)) {
                        this.preSelectedRowIds.push(rows[i].contact_id);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].contact_id;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map((r) => r.contact_id).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }
        },
        onlyOwnCompanyContacts() {
            if (this.onlyOwnCompanyContacts) {
                this.selectedUser = null;
            }
        },
        selectedUser() {
            if (this.selectedUser) {
                this.onlyOwnCompanyContacts = false;
            }
        },
        searchQuery(val) {
            const onlyIds = val && /^[\d\s,]+$/.test(val);
            if (onlyIds) {
                this.contactIds = val
                    .replaceAll(' ', ',')
                    .split(',')
                    .filter((x) => x);
            } else {
                this.contactIds = null;
            }
        },
        hideTemporaryContacts() {
            if (this.hideTemporaryContacts) {
                this.onlyTemporaryContacts = false;
            }
        },
        onlyTemporaryContacts() {
            if (this.onlyTemporaryContacts) {
                this.hideTemporaryContacts = false;
            }
        },
        onlyInactiveContacts() {
            if (this.onlyInactiveContacts) {
                this.hideInactiveContacts = false;
            }
        },
        hideInactiveContacts() {
            if (this.hideInactiveContacts) {
                this.onlyInactiveContacts = false;
            }
        }
    },
    created() {
        if (this.company) {
            this.selectedCompany = this.company;
        }
        if (this.contactListId) {
            this.$axios.get('/api/contact_lists/' + this.contactListId).then((res) => {
                this.selectedContactGroup = res.data;
            });
        }
        if (this.event) {
            this.selectedEvent = this.event;
        }
        if (this.showFilters) {
            this.loadContactFilterOptions();
            this.loadContactTypeOptions();
            this.loadContactDepartmentOptions();
            this.loadMostUsedContactGroups();
            this.loadMostUsedCompanyGroups();
            this.loadPreparedUsersForSelect();
        }
        this.baseKey = this.generateUUID();
    },
    mounted() {
        this.$nextTick(() => {
            if (this.search) {
                this.updateSearchQuery(this.search);
                this.searchInput = this.search;
            }

            if (this.$route.query.hasOwnProperty('company') && this.$route.query.company > 0) {
                this.getCompanyById(this.$route.query.company).then((company) => {
                    this.selectedCompany = company;
                });
            }
        });
    },
    methods: {
        fetchTooltipData: debounce(async function(contact) {
            const { id: contactId } = contact;
            await this.$nextTick();

            try {
                // Simulate API call
                this.$axios.get(`api/contacts/get_meeting_info/` + contactId).then(({ data }) => {
                    const { totalCount, twoYearCount, lastMeetingDate, lastMeetingPrivate } = data;

                    const text =
                        `Latest Interaction: ${lastMeetingDate}` +
                        (lastMeetingPrivate ? ' (private)' : '') +
                        '<br>' +
                        `Meetings past 2 years: ${twoYearCount}` +
                        '<br>' +
                        `Total Meetings: ${totalCount}`;

                    const tooltipElement = document.querySelector(`#status-badge-${contactId}`);
                    const tooltip = this.$bootstrap.Tooltip.getInstance(tooltipElement);
                    tooltip.tip.querySelector(`.tooltip-inner`).innerHTML = text;
                    tooltip.update();
                });
            } catch (error) {
                this.$set(this.tooltips, contactId, 'Error loading tooltip');
            }
        }, 300),

        editContact(contactId) {
            this.$axios.get('/api/contacts/' + contactId).then((response) => {
                this.contactToEdit = response.data;
                this.$nextTick(() => {
                    this.modal = this.openModal(this.$refs.editContactModal);
                });
            });
        },
        addContactsToSessions() {
            this.pushToSessions = +new Date();

            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.pushContactsToSessionsModal);
            });
        },
        continueContact(contactId, alreadyContinuesAnotherContact, alreadyContinuedByAnotherContact) {
            this.$axios.get('/api/contacts/' + contactId).then((response) => {
                this.contactToEdit = response.data;
                this.alreadyContinuesAnotherContact = alreadyContinuesAnotherContact;
                this.alreadyContinuedByAnotherContact = alreadyContinuedByAnotherContact;
                this.$nextTick(() => {
                    this.modal = this.openModal(this.$refs.continueContactModal);
                });
            });
        },
        openBulkOperationModal(mode) {
            this.mode = mode;
            this.bulkUpdated = moment().unix();
            this.bulkTriggered = true;

            this.$nextTick(() => {
                this.bulkModal = this.openModal(this.$refs.contactBulkOperationModal);
            });
        },
        onBulkSubmitted(update = true) {
            if (update) {
                this.updateTable();
            }
            this.closeModal(this.bulkModal);
        },
        deleteContact(id) {
            this.contactToDeleteId = id;
            this.modal = this.openModal(this.$refs.deleteContactModal);
        },
        removeContactFromEvent(id) {
            this.contactToRemoveFromEventId = id;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.removeFromEventModal);
            });
        },
        eventContactsUpdated() {
            this.$emit('event-contacts-updated');
            this.closeModal(this.modal);
        },
        cancelDeleteContact() {
            this.contactToDeleteId = null;
        },
        addContact() {
            this.modal = this.openModal(this.$refs.addContactModal);
        },
        contactUpdatedHandler() {
            if (this.modal) {
                this.closeModal(this.modal);
                this.modal = null;
            }
            this.updateTable();
            this.contactToDeleteId = null;
        },
        onContactsImported() {
            this.closeModal(this.importModal);
            this.updateTable();
            this.$emit('contacts-updated');
        },
        openImportModal() {
            this.importModal = this.openModal(this.$refs.importModal);
        },

        initializeTooltip(element, options) {
            return new this.$bootstrap.Tooltip(element, options);
        },
        onRowsUpdated(data) {
            this.tableLoaded = true;

            setTimeout(() => this.initializeOptionButtonTooltips('contact_list'), 250);
            this.$nextTick(() => {
                const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
                tooltipTriggerList.forEach((tooltipTriggerEl) => {
                    this.initializeTooltip(tooltipTriggerEl, {
                        template: '<div class="tooltip" role="tooltip"><div class="tooltip-inner"></div></div>',
                        html: true
                    });
                });
            });

            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = JSON.parse(JSON.stringify(this.preSelectedRowIds)).map((id) => {
                    return {
                        key: 'contact_id',
                        value: id
                    };
                });
            }

            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;

            if (this.initialLoad && !this.ajaxRows.length) {
                this.$emit('empty');
            }

            this.initialLoad = false;

            // if we are on a company page show a contact
            if (this.company && this.$route.fullPath.includes('companies/contacts')) {
                // Displaying contacts of a company: Find contact to display (key or first)
                let rowToDisplay = null;
                for (let i = 0; i < data.rows.length; i++) {
                    // Check if we have a key contact
                    if (data.rows[i].cells[0].contact.isKeyContact) {
                        // Display this contact as it is the first key contact
                        rowToDisplay = data.rows[i];
                        break;
                    }
                }

                // No Key Contact found, display first contact in list
                if (data.rows.length > 0 && !rowToDisplay) {
                    rowToDisplay = data.rows[0];
                }
                if (rowToDisplay) {
                    this.$emit('row-clicked', rowToDisplay);
                }
            } else if (!this.company && data.rows.length > 0) {
                // Display all contacts: first contact
                this.$emit('row-clicked', data.rows[0]);
            }

            this.$emit('rows-updated');
        },
        loadContactDepartmentOptions() {
            this.$axios.get('/api/contact_departments').then((response) => {
                // console.log("Contact Departments loaded");
                this.contactDepartmentOptions = response.data['hydra:member'];
            });
        },
        loadContactTypeOptions() {
            this.$axios.get('/api/contact_types').then((response) => {
                this.contactTypeOptions = response.data['hydra:member'];
            });
        },
        loadPreparedUsersForSelect() {
            this.$axios.get('/api/users/for_select').then((response) => {
                this.preparedUsersForSelect = response.data['hydra:member'];
            });
        },
        updateTable() {
            this.tableUpdated = new Date();
        },
        rowClicked(row) {
            this.$emit('row-clicked', row);
            if (this.selectedRow) {
                this.selectedRow.classes = '';
            }
            row.classes = 'showing-contact-details';
            this.selectedRow = row;
        },
        downloadBulkContacts() {
            this.closeModal(this.bulkModal);

            switch (this.mode) {
                case 'selected':
                    this.downloadQuery = {
                        filters: {
                            ids: structuredClone(this.preSelectedRowIds)
                        }
                    };
                    break;

                case 'page':
                    this.downloadQuery = {
                        filters: {
                            ids: this.ajaxRows.map(function(row) {
                                return row.contact_id;
                            })
                        }
                    };
                    break;

                case 'filtered':
                default:
                    this.downloadQuery = {
                        search: this.searchQuery,
                        filters: this.filters
                    };
                    break;
            }

            this.openModal(this.$refs.downloadContactsModal);
        },
        downloadContacts() {
            this.downloadQuery = {
                search: this.filters.contactIds ? '' : this.searchQuery,
                filters: this.filters
            };

            this.openModal(this.$refs.downloadContactsModal);
        },
        updateSearchQuery(searchQuery) {
            this.pageChanged = true;
            this.searchQuery = searchQuery;
        },
        resetAddContact() {
            this.addContactReset = moment().unix();
        },
        contactUpdated() {
            this.contactsUpdated = moment().unix();
            this.contactToEdit = null;
            this.closeModal(this.modal);
            this.$emit('contacts-updated');
        },
        onPageChange() {
            this.pageChanged = true;
        },

        clearPreSelection() {
            this.preSelectedRowIds = [];
            this.preSelectedRows = [];
        },

        loadMostUsedContactGroups() {
            return this.$axios.get('/api/contact_lists/most_used?groups[]=contact_list:basics').then((response) => {
                this.mostUsedContactGroups = response.data['hydra:member'];
            });
        },
        loadMostUsedCompanyGroups() {
            return this.$axios.get('/api/company_groups/most_used').then((response) => {
                this.mostUsedCompanyGroups = response.data['hydra:member'];
            });
        },
        showOfficeContacts() {
            this.openModal(this.$refs.officeContactsModal);
        },
        loadContactFilterOptions() {
            let url = `/api/contacts/filter_options?ts=1703236408`;
            if (this.event && this.event.id) {
                url = `/api/contacts/filter_options?event=${this.event.id}&ts=1703236408`;
            }

            this.$axios.get(url).then((response) => {
                this.filterOptions = response.data;
            });
        },
        resetFilters() {
            if (!this.company) {
                this.selectedCompany = null;
            }
            if (!this.event) {
                this.selectedEvent = null;
            }

            this.searchInput = null;
            this.selectedUser = null;
            this.selectedContactGroup = null;
            this.selectedCompanyGroup = null;
            this.selectedTargetingGroup = null;
            this.selectedCrossConnectionUser = null;

            this.selectedContactTypes = [];
            this.selectedInvestorTiers = [];
            this.selectedContactDepartments = [];
            this.selectedCities = [];
            this.selectedStates = [];
            this.selectedCountries = [];
            this.selectedGenders = [];
            this.selectedEventRole = null;
            this.selectedIsAttendingEvent = this.event ? ['Yes', 'Unknown'] : [];
            this.selectedHasAttendedEvent = null;

            this.onlyOwnCompanyContacts = false;
            this.hideInactiveContacts = true;
            this.hideTemporaryToDeleteContacts = true;
            this.hideTemporaryContacts = true;
            this.hideExcludedFromEmails = false;
            this.excludedFromEmailsOnly = false;
            this.hideNoCompanyContacts = false;
            this.favoritesOnlyFilter = false;
            this.contactsFromFavoriteCompanies = false;

            this.area = null;

            this.contactsUpdated = moment().unix();
            this.mapKey = this.generateUUID();

            this.companyCharacteristics = [];
            this.companyRoles = [];
            this.isPrivateCompany = null;
            this.selectedMarketListMembers = [];
            this.selectedSessionsTypes = [];
            this.onlyMarketListContacts = false;
            this.onlyBlackList = false;
            this.minSessionsMeetingRequests = null;
            this.maxSessionsMeetingRequests = null;
        },
        async showKnownPeople(id) {
            await this.$axios
                .get(`/api/contacts/known_people/${id}?groups[]=knownPeople:read`)
                .then((response) => {
                    this.knownPeople = response.data.relationships;
                    this.fromContactId = response.data.fromContactId;
                })
                .finally(() => {
                    this.openModal(this.$refs.contactKnownPeopleModal);
                });
        },
        contactContinued() {
            this.closeModal(this.modal);
            this.continueModalUpdated = moment().unix();
            this.updateTable();
        },
        updateParticipation(contactId, updatedParticipation) {
            this.participationByContactIds[contactId] = updatedParticipation;
        },
        syncWithSessions() {
            this.isSyncingWithSessions = true;
            this.$axios
                .post(`/api/events/${this.event.id}/sessions_clients_sync`)
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: 'success',
                        title: 'Success',
                        message: response.data.message,
                        id: toastId
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.tableUpdated = new Date();
                })
                .finally(() => {
                    this.isSyncingWithSessions = false;
                });
        },
        updateContactType(cell, value) {
            cell.state = 'saving';
            this.$axios
                .patch(
                    'api/contacts/' + cell.contactId,
                    { contactType: value },
                    { headers: { 'Content-Type': 'application/merge-patch+json' } }
                )
                .then((response) => {
                    cell.text = response.data.contactType.title;
                    this.$emit('contact-type-updated');
                })
                .finally(() => {
                    cell.state = 'saved';
                    cell.unknown = false;
                    this.loading = false;
                });
        }
    }
};
