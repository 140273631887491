
import moment from 'moment';
import SaveButton from '../save-button';
import ContactEdit from './contact-edit';
import iriPreparation from '~/mixins/iri-preparation';

export default {
    name: 'AddContactModal',
    components: { ContactEdit, SaveButton },
    mixins: [iriPreparation],
    props: {
        id: {
            type: String,
            default: 'addContactModal'
        },
        forCompany: {
            type: Object,
            default: null
        },
        forwardToContactPage: {
            type: Boolean,
            required: false,
            default: true
        },
        emailRequired: {
            type: Boolean,
            default: false
        },
        isAboveModal: {
            type: Boolean,
            default: false
        },
        initialContactData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            contactTemplate: null,
            contactToEdit: null,
            loading: false,
            loadingTemplate: false,
            modalId: moment().unix(),
            addBiopharma: false,
            addPrivate: false
        };
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.modalId = moment().unix();
            this.addEmptyContact();
        });
    },
    methods: {
        async addEmptyContact() {
            let url = '/api/contacts/empty';

            const urlParams = {};
            if (this.forCompany && this.forCompany.id > 0) {
                urlParams.company = this.forCompany.id;
            }
            if (this.initialContactData && this.initialContactData.companyName) {
                urlParams.companyName = this.initialContactData.companyName;
            }
            if (this.initialContactData && this.initialContactData.contactName) {
                urlParams.name = this.initialContactData.contactName;
            }

            url += ('?' + this.buildQueryString(urlParams));

            const response = await this.$axios.get(url);
            this.contactToEdit = response.data;
        },
        addContactFromTemplate() {
            this.loadingTemplate = true;

            const params = this.buildQueryString({
                uuid: this.contactTemplate.uuid
            });
            this.$axios.get('/api/contacts/prepare?' + params)
                .then((response) => {
                    this.contactToEdit = response.data;
                }).finally(() => {
                this.loadingTemplate = false;
            });
        },
        reset() {
            this.$emit('reset');
        },
        async submitForm() {
            if (this.contactToEdit.isExcludedFromEmails === false) {
                this.contactToEdit.reasonForExclusion = '';
            }

            this.contactToEdit = this.prepareIri(this.contactToEdit);
            this.contactToEdit.addBiopharmaInvestmentPreference = this.addBiopharma;
            this.contactToEdit.addPrivateInvestmentPreference = this.addPrivate;

            let createdCompany = null;

            if (this.contactToEdit.company && this.contactToEdit.company['search-select-created-option']) {
                const response = await this.$axios.post('/api/companies', this.contactToEdit.company);
                this.contactToEdit.company = response.data['@id'];
                createdCompany = response.data;
            }

            this.loading = true;

            // Send request to server
            this.$axios.post('/api/contacts', this.contactToEdit)
                .then((response) => {
                    this.$emit('contact-updated', response.data);

                    const toastIds = [];

                    const toastIdUser = this.generateUUID();
                    this.addToast({
                        type: 'success',
                        title: 'Saved',
                        message: `Contact created`,
                        id: toastIdUser
                    });

                    toastIds.push(toastIdUser);

                    if (createdCompany) {

                        const toastIdCompany = this.generateUUID();
                        this.addToast({
                            type: 'success',
                            title: 'Saved',
                            message: `New company "${createdCompany.name}" was created, click here to view details`,
                            onClicked: () => {
                                this.$router.push('/companies/' + createdCompany.id);
                            },
                            id: toastIdCompany
                        });
                        toastIds.push(toastIdCompany);
                    }

                    this.$nextTick(() => {
                        toastIds.forEach((toastId) => {
                            this.toggleToast(toastId);
                        });
                        if (this.forwardToContactPage) {
                            this.$router.push(`/contacts/${response.data.id}`);
                        }
                    });

                }).finally(() => {
                this.loading = false;
            });

        }
    }
};
