
import EmailDropdown from '../email-dropdown.vue';

export default {
    name: 'ContactEmailCell',
    components: { EmailDropdown },
    props: {
        cell: {
            type: Object,
            required: true
        }
    },
    computed: {
        excludedEmailTitle() {
            if (this.cell.reason === null || this.cell.reason === '' || this.cell.reason === undefined) {
                return 'Excluded from emails';
            } else {
                return 'Excluded from emails because: ' + this.cell.reason;
            }
        }
    }
};
