
import LoadingSpinner from '../loading-spinner';
import EditNoteModal from '../notes/edit-note-modal';
import UserCoverageModal from '../users/user-coverage-modal';
import GmapsAddress from '../gmaps-address';
import ContactListModal from '../contact-list-modal';
import modals from '../../mixins/modals';
import optionButtonTooltips from '../../mixins/option-button-tooltip';
import HistoryModal from '../history-modal';
import FavStar from '../fav-star';
import RedFlag from '../red-flag';
import EditCallLogModal from '../call-log/edit-call-log-modal.vue';
import iriPreparation from '../../mixins/iri-preparation';
import { SEVERITY_BLACKLIST, SEVERITY_HIGH, SEVERITY_MEDIUM } from '../../mixins/red-flag';
import { INVESTOR_LOG } from '../../mixins/call-log';
import { TIER_LABELS } from '../../mixins/contact-tiers';
import EditContactModal from './edit-contact-modal';
import KeyContact from './key-contact';
import EmailDropdown from './email-dropdown';
import ContactDeleteModal from './contact-delete-modal';
import ContactKnownPeopleModal from './contact-known-people-modal';
import ContinueContactModal from './continue-contact-modal';
import ContactGoogleIcon from './helpers/contact-google-icon.vue';
import ContactName from './contact-name.vue';

export default {
    name: 'ContactProfileCard',
    components: {
        ContactName,
        ContactGoogleIcon,
        EditCallLogModal,
        RedFlag,
        FavStar,
        ContinueContactModal,
        HistoryModal,
        EmailDropdown,
        ContactListModal,
        KeyContact,
        GmapsAddress,
        EditContactModal,
        LoadingSpinner,
        EditNoteModal,
        ContactDeleteModal,
        ContactKnownPeopleModal,
        UserCoverageModal
    },
    mixins: [modals, optionButtonTooltips, iriPreparation],
    props: {
        contactId: {
            type: Number,
            default: null
        },
        fromDetail: {
            type: Boolean,
            default: false
        },
        onContactsTable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            SEVERITY_HIGH,
            SEVERITY_MEDIUM,
            SEVERITY_BLACKLIST,
            contact: {},
            isLoading: false,
            modal: null,
            noteToEdit: null,
            contactListModal: null,
            historyModal: null,
            updatingContactAddress: false,
            updatingCompanyAddress: false,
            knownPeople: [],
            fromContactId: [],
            isFlaggedByCurrentUser: false,
            uuid: this.generateUUID(),
            showRedFlagModalTrigger: null,
            callLogModalKey: this.generateUUID(),
            callLogToEdit: null,
            showAlternativeEmails: false,
            researchCoverageModalKey: this.generateUUID(),
            TIER_LABELS
        };
    },
    computed: {
        vCardUrl() {
            return '/api/contacts/vcard/' + this.contactId;
        },
        noteKey() {
            return JSON.stringify(this.noteToEdit);
        },
        isForContact() {
            return this.contactId !== null;
        },
        currentlyknowsPersonallyRelation() {
            return this.contact.relationshipIdForCurrentUserKnowsContactPersonally;
        },
        isDuplicateAddress() {
            if (this.contact.address && this.contact.company) {
                return this.contact.address === this.contact.company.address;
            } else {
                return false;
            }
        },
        contactsContinuedByThisContact() {
            return this.contact.contactsContinuedByThisContact;
        },
        contactsContinuingThisContact() {
            return this.contact.contactsContinuingThisContact;
        },
        alreadyContinuesAnotherContact() {
            return this.contactsContinuedByThisContact?.length > 0;
        },
        alreadyContinuedByAnotherContact() {
            return this.contactsContinuingThisContact?.length > 0;
        },
        isInvestorContact() {
            return this.contact.company && (this.contact.company.isBdTarget === false);
        },
        allKnownPeopleAreInactive() {
            return this.knownPeople.every((kp) => !kp.fromContact.isActive || !kp.toContact.isActive);
        }
    },

    watch: {
        contactId() {
            this.loadAndUpdate();
        }
    },
    created() {
        if (this.contactId > 0) {
            this.loadAndUpdate();
        }
    },
    methods: {
        loadAndUpdate() {
            this.loadContact();
            this.loadKnownPeople();
        },
        addToContactList() {
            this.contactListModal = this.openModal(this.$refs.contactListModal);
        },
        openHistory() {
            this.historyModal = this.openModal(this.$refs.historyModal);
        },
        continueContact() {
            this.openModal(this.$refs.continueContactModal);
        },
        editContact() {
            this.modal = this.openModal(this.$refs.editContactModal);
        },
        loadContact() {
            if (this.contactId > 0) {
                this.isLoading = true;
                this.$axios.get(`/api/contacts/${this.contactId}?groups[]=contact:with_continues_contact&groups[]=contact:read`)
                    .then((response) => {
                        this.contact = response.data;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.$nextTick(() => {
                            this.initializeOptionButtonTooltips();
                        });
                    });
            }
        },
        openRedFlagModal() {
            this.showRedFlagModalTrigger = this.generateUUID();
        },
        contactUpdated() {
            this.$emit('contact-updated');
            this.loadContact();
            this.closeModal(this.modal);
        },
        favUpdated() {
            this.$emit('contact-updated');
        },
        redFlagUpdated() {
            this.$emit('contact-updated');
            this.loadContact();
        },
        copyContactInfo() {
            let contactData = {
                'Name': this.contact.name,
                'Company': this.contact.company ? this.contact.company.name : null,
                'Title': this.contact.jobTitle,
                'Department': this.contact.contactDepartment ? this.contact.contactDepartment.title : null,
                'Address': this.contact.address ? this.contact.address : null,
                'Company Address': this.contact.company ? this.contact.company.address : null,
                'Website': this.contact.company ? this.contact.company.website : null,
                'Phone': this.contact.phoneFormatted,
                'Cell': this.contact.cellFormatted,
                'Email': this.contact.email,
                'Twitter': this.contact.twitter_url,
                'linked': this.contact.linked_url
            };

            // eslint-disable-next-line no-unused-vars
            contactData = Object.fromEntries(Object.entries(contactData).filter(([_, v]) => v != null));
            let contactInfo = '';
            for (const [key, value] of Object.entries(contactData)) {
                if (key === 'Address' || key === 'Company Address') {
                    contactInfo += `${key}:\n${value}\n`;
                } else {
                    contactInfo += `${key}: ${value}\n`;
                }
            }
            const el = document.createElement('textarea');
            el.value = contactInfo;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
            const toastId = this.generateUUID();
            this.addToast({
                type: 'success',
                title: 'Success',
                message: 'Contact information copied to clipboard',
                id: toastId
            });
            this.$nextTick(() => {
                this.toggleToast(toastId);
            });
        },
        addNote() {
            return this.$axios.get('/api/notes/empty')
                .then((response) => {
                    this.noteToEdit = response.data;
                    this.$nextTick(this.openAddNoteModal);
                });
        },
        openAddNoteModal() {
            this.modal = this.openModal(this.$refs.addNoteModal, {
                backdrop: 'static',
                keyboard: false
            });
        },
        notesUpdated() {
            this.closeModal(this.modal);
        },
        knowsPersonally() {
            let promise = null;
            let message = null;
            if (this.currentlyknowsPersonallyRelation) {
                promise = this.$axios.delete('/api/relationships/' + this.currentlyknowsPersonallyRelation.id)
                    .then(() => {
                        message = 'Relationship deleted.';
                    });
            } else {
                promise = this.$axios.post('/api/contacts/' + this.contact.id + '/user_knows_personally')
                    .then(() => {
                        message = 'Relationship saved.';
                    });
            }
            promise.then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: 'success',
                    title: 'Success',
                    message,
                    id: toastId
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.loadAndUpdate();
            });
        },
        addContactToOutlook() {
            this.$axios.post('/api/graph/contact/export/' + this.contact.id)
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: 'success',
                        title: 'Success',
                        message: response.data.message,
                        id: toastId
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                });
        },
        deleteContact() {
            this.modal = this.openModal(this.$refs.deleteContactModal);
        },
        contactDeletionHandler() {
            if (this.fromDetail) {
                this.$router.push('/contacts');
            } else {
                if (this.modal) {
                    this.closeModal(this.modal);
                    this.modal = null;
                }
                this.$emit('contact-deleted');
            }
        },
        contactReplacedHandler(newId) {
            if (this.onContactsTable) {
                this.$emit('contact-replaced', newId);
            } else {
                this.$router.push('/contacts/' + newId);
            }
        },
        contactUpdatedHandler() {
            this.$emit('contact-updated');
            if (this.modal) {
                try {
                    this.closeModal(this.modal);
                    this.modal = null;
                } catch (e) {
                    console.error(e);
                }
            }
            this.loadContact();
        },
        copyAddress(source, destination) {
            destination.address1 = source.address1;
            destination.address2 = source.address2;
            destination.city = source.city;
            destination.state = source.state;
            destination.zip = source.zip;
            destination.country = source.country;
            destination.latitude = source.latitude;
            destination.longitude = source.longitude;
        },
        overwriteContactAddress() {
            this.updatingContactAddress = true;

            const finalContact = structuredClone(this.contact);
            finalContact.users = this.prepareIri(finalContact.users);
            finalContact.assistantContact = this.prepareIri(finalContact.assistantContact);
            this.copyAddress(this.contact.company, finalContact);
            for (const propertyName of ['contactDepartment', 'company', 'contactType']) {
                if (finalContact[propertyName]) {
                    finalContact[propertyName] = this.contact[propertyName]['@id'];
                }
            }

            this.$axios.patch(`/api/contacts/with_relationships/${finalContact.id}`, finalContact, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then(response => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: 'success',
                    title: 'Contact Updated',
                    message: 'Contact address is successfully overwritten with company address',
                    id: toastId
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });

                this.contact = response.data;
                this.$emit('contact-updated', this.contact);
            }).finally(() => {
                this.updatingContactAddress = false;
            });
        },
        overwriteCompanyAddress() {
            this.updatingCompanyAddress = true;

            const finalCompany = structuredClone(this.contact.company);
            this.copyAddress(this.contact, finalCompany);

            this.$axios.patch(`/api/companies/${finalCompany.id}`, finalCompany, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then(response => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: 'success',
                    title: 'Contact Updated',
                    message: 'Company address is successfully overwritten with contact address',
                    id: toastId
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });

                this.contact.company = response.data;
                this.$emit('contact-updated', this.contact);
            }).finally(() => {
                this.updatingCompanyAddress = false;
            });
        },
        showKnownPeople() {
            this.openModal(this.$refs.contactKnownPeopleModal);
        },
        loadKnownPeople() {
            this.knownPeople = [];
            this.fromContactId = [];
            this.$axios.get(`/api/contacts/known_people/${this.contactId}?groups[]=knownPeople:read`)
                .then((response) => {
                    this.knownPeople = response.data.relationships;
                    this.fromContactId = response.data.fromContactId;
                });
        },
        contactListsUpdated() {
            this.closeModal(this.contactListModal);
            this.$emit('contact-list-update');
        },
        addCallLog() {
            this.callLogToEdit = null;
            this.callLogModalKey = this.generateUUID();
            this.$axios.get(`/api/call_logs/template/${INVESTOR_LOG}?contact=${this.contactId}&company=${this.contact?.company.id}`)
                .then(async (response) => {
                    this.callLogToEdit = response.data;
                    await this.$nextTick();
                    this.modal = this.openModal(this.$refs.callLogModal);
                });
        },
        closeCallLogModal() {
            if (this.modal) {
                this.closeModal(this.modal);
                this.modal = null;
            }
        },
        showCompanies() {
            this.modal = this.openModal(this.$refs.userCoverageModal);
        }
    }
};
