

import { mapActions, mapGetters } from 'vuex';
import LoadingSpinner from '../loading-spinner';
import ContactBulkOperationForm from './contact-bulk-operation-form';

export default {
    name: 'ContactBulkOperationModal',
    components: {
        LoadingSpinner,
        ContactBulkOperationForm
    },
    props: {
        filters: {
            type: Object,
            default: () => {
                return {};
            }
        },
        contactList: {
            type: Object,
            default: null
        },
        event: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            bulkContacts: null,
            loading: false,
            contactsHash: null,
            contactsCount: null,
            allowResendEventConfirmEmail: false,
            greyedOutReason: null
        };
    },
    computed: {
        ...mapGetters({
            bulkContactFiltersHash: 'app/bulkContactFiltersHash'
        })
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.loadBulkContacts();
        });
    },
    methods: {
        ...mapActions({
            setBulkContactFiltersHash: 'app/setBulkContactFiltersHash'
        }),
        async loadBulkContacts() {
            this.loading = true;
            const responseFilters = await this.$axios.post('/api/contacts/save_bulk_filters', {
                filters: this.filters,
                eventId: this.event?.id
            });

            // Store contact_hash in store
            await this.setBulkContactFiltersHash(responseFilters.data.hash);

            this.bulkContacts = {
                hash: responseFilters.data.hash,
                count: responseFilters.data.contactCount
            };
            this.allowResendEventConfirmEmail = responseFilters.data.allow_resend_confirmation_email;
            this.greyedOutReason = responseFilters.data.greyedOutReason;
            this.loading = false;
        },
        submitted() {
            this.$emit('submitted');
        },
        exportAsExcel() {
            this.$emit('export-as-excel');
        }
    }
};
